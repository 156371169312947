(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/alert/assets/javascripts/alert-flash.js') >= 0) return;  svs.modules.push('/components/components/alert/assets/javascripts/alert-flash.js');

var svs = svs || {};


(function(svs) {
  'use strict';

  var type = {
    error: svs.components.Alert.Type.ERROR,
    info: svs.components.Alert.Type.INFO,
    success: svs.components.Alert.Type.INFO
  };
  svs = svs || {};

  String.prototype.replaceAt = function(index, character) {
    return (
      this.substr(0, index) +
      character +
      this.substr(index + character.length - 1)
    );
  };
  function htmlEscape(str) {
    return String(str)
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#39;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;');
  }
  function svsMarkup(str) {
    var endtagpos; var starttagpos;
    str = htmlEscape(str);
    str = String(str)
      .replace(/\[br\]/g, '<br>')
      .replace(/\[p\]/g, '<p>')
      .replace(/\[\/p\]/g, '</p>')
      .replace(/\[div\]/g, '<div>')
      .replace(/\[\/div\]/g, '</div>')
      .replace(/\[span\]/g, '<span>')
      .replace(/\[\/span\]/g, '</span>');

    while (
      str.indexOf('[p=') > -1 ||
      str.indexOf('[div=') > -1 ||
      Boolean(~str.indexOf('[span='))
    ) {
      if (str.indexOf('[p=') > -1) {
        starttagpos = str.indexOf('[p=');
        endtagpos = str.indexOf(']', starttagpos);
        str = str.replaceAt(endtagpos, "'>");
        str = str.replace('[p=', "<p class='");
      }
      if (str.indexOf('[div=') > -1) {
        starttagpos = str.indexOf('[div=');
        endtagpos = str.indexOf(']', starttagpos);
        str = str.replaceAt(endtagpos, "'>");
        str = str.replace('[div=', "<div class='");
      }
      if (~str.indexOf('[span=')) {
        starttagpos = str.indexOf('[span=');
        endtagpos = str.indexOf(']', starttagpos);
        str = str.replaceAt(endtagpos, "'>");
        str = str.replace('[span=', "<span class='");
      }
    }
    return str;
  }

  function createMessageArray(message) {
    if (typeof message === 'string') {
      message = message ? message.replace(/(<([^>]+)>)/gi, '') : '';
      return [
        {
          text: message,
          type: svs.components.dialog.message.TEXT
        }
      ];
    }

    try {
      if (Array.isArray(message)) {
        message.forEach(function(el) {
          if (el && el.text && typeof el.text === 'string') {
            el.text = el.text ? el.text.replace(/(<([^>]+)>)/gi, '') : '';
          }
        });
        return message;
      }
    } catch (err) {
    }
    return [
      {
        text: '',
        type: svs.components.dialog.message.TEXT
      }
    ];
  }

  function createAlert(type, obj) {
    if (typeof obj === 'string') {
      new svs.components.Alert(svsMarkup(obj), { type: type }).show();
    } else {
      new svs.components.Alert(svsMarkup(obj.message), {
        type: type,
        time: obj.time
      }).show();
    }
  }

  function createDialog(type, alert) {
    var branding =
        alert.branding === 'tur' ?
          svs.components.dialog.branding.TUR :
          svs.components.dialog.branding.SPORT;
    var dialogType =
        type === 'error' ?
          svs.components.dialog.type.ERROR :
          svs.components.dialog.type.DEFAULT;
    var title = alert.title.replace(/(<([^>]+)>)/gi, '');
    var message = createMessageArray(alert.message);
    var area =
        alert.area === 'popup' ?
          svs.components.dialog.area.POPUP :
          svs.components.dialog.area.TOASTER;
    var dialog;
    var dialogOptions = {
      branding: branding,
      area: area,
      autoClose: alert.time ? alert.time : 0,
      title: title,
      type: dialogType,
      message: message,
      actions: [
        {
          title: 'Okej',
          callback: function() {
            dialog && dialog.close();
          }
        }
      ]
    };

    if (alert.icon) {
      dialogOptions.icon = alert.icon;
    }

    dialog = new svs.components.dialog.Confirm(dialogOptions);

    setTimeout(function() {
      svs.components.dialog.api.add(dialog);
    }, 300);
  }


  svs.core.flash.flush(function(messages) {
    var dialogMessages = [];
    Object.keys(messages).forEach(function(messageType) {
      messages[messageType].forEach(function(message) {
        if (type[messageType]) {
          if (!message.title) {
            createAlert(type[messageType], message);
          }
        }
      });
    });

    if (dialogMessages.length) {
      svs.core.module.load('/cl/components/dialog', null, function() {
        dialogMessages.forEach(function(message) {
          createDialog(message.type, message);
        });
      });
    }
  });
})(svs);


 })(window);