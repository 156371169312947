(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/alert/views/alert.js') >= 0) return;  svs.modules.push('/components/components/alert/views/alert.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_cmps=_svs.components=_svs.components||{};
_cmps.alert=_cmps.alert||{};
_cmps.alert.templates=_cmps.alert.templates||{};
svs.components.alert.templates.alert = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"alert "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"type") || (depth0 != null ? lookupProperty(depth0,"type") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data,"loc":{"start":{"line":1,"column":18},"end":{"line":1,"column":26}}}) : helper)))
    + " clearfix\" role=\"alertdialog\" aria-describedby=\"alert-message\">\n  <span class=\"padding-right-3 block alert-message\" id=\"alert-message\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"message") || (depth0 != null ? lookupProperty(depth0,"message") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"message","hash":{},"data":data,"loc":{"start":{"line":2,"column":71},"end":{"line":2,"column":84}}}) : helper))) != null ? stack1 : "")
    + "</span>\n  <a class=\"right alert-close js-alert-close\" tabindex=\"0\" title=\"Stäng dialog\"><i class=\"alert-close-icon icon-default icon-close\"></i></a>\n</div>";
},"useData":true});
Handlebars.partials['components-components-alert-alert'] = svs.components.alert.templates.alert;
})(svs, Handlebars);


 })(window);