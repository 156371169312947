(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/alert/assets/javascripts/alert.js') >= 0) return;  svs.modules.push('/components/components/alert/assets/javascripts/alert.js');

var svs = svs || {};

(function(svs) {
  'use strict';

  svs.components = svs.components || {};

  svs.components.Alert = function(message, options) {
    var alertElement = null;
    var showElement = $('body'); 
    var afterShowCallback = null;
    var showTimer = null;

    return {
      show: function(callback) {
        var self = this;
        var time = (options || {}).time || svs.components.Alert.Time.DEFAULT;
        var type = (options || {}).type || svs.components.Alert.Type.DEFAULT;
        var alertModel = {
          type: type,
          message: message
        };
        afterShowCallback = callback;
        alertElement = $(svs.components.alert.templates.alert(alertModel));

        this.keyListenerId = svs.utils.keylistener.addKeyboardListener(function(event) {
          self.handleKeys(event);
        });

        showTimer = setTimeout(function() {
          self.close();
        }, time);

        var wrapper = $(svs.components.alert.createWrapper());

        wrapper.append(alertElement);

        alertElement.on('click', '.js-alert-close', this.close);

        setTimeout(function() {
          alertElement.addClass('active');
          alertElement.find('.js-alert-close').focus();

          $(document).on('click', 'body', self.close);
        }, 20);
        return this;
      },

      close: function(e) {
        if (e) {
          e.stopPropagation();
        }

        svs.utils.keylistener.removeKeyboardListener(this.keyListenerId);

        var self = this;
        var removeAlert = function() {
          alertElement.removeClass('active'); 
          alertElement.off('click');
          setTimeout(function() {
            alertElement.remove();
            $(document).off('click', 'body', self.close);
            if (typeof afterShowCallback === 'function') {
              afterShowCallback();
            }
          }, 400); 
        };
        clearTimeout(showTimer);
        if (e) {
          var target = $(e.target);

          if (
            target.hasClass('js-alert-close') ||
            target.hasClass('alert-close-icon') ||
            target.parents('.alert-wrapper').length === 0
          ) {
            removeAlert();
            return true;
          }
          return true;
        }
        removeAlert();
      },

      handleKeys: function(event) {
        if (event.keyCode === svs.utils.keylistener.KEY_ESC) {
          this.close();
        } else if (event.keyCode === svs.utils.keylistener.KEY_ENTER) {
          this.close();
        }
      },

      isShowing: function() {
        var isShowing;

        if (
          !showElement ||
          !showElement[0] ||
          !alertElement ||
          !alertElement[0]
        ) {
          isShowing = false;
        } else {
          isShowing = $.contains(showElement[0], alertElement[0]);
        }

        return isShowing;
      }
    };
  };

  svs.components.Alert.Time = {};
  svs.components.Alert.Time.SHORT = 3000;
  svs.components.Alert.Time.MEDIUM = 5000;
  svs.components.Alert.Time.LONG = 10000;
  svs.components.Alert.Time.VERY_LONG = 20000;
  svs.components.Alert.Time.DEFAULT = svs.components.Alert.Time.LONG;

  svs.components.Alert.Type = {};
  svs.components.Alert.Type.INFO = 'info';
  svs.components.Alert.Type.ERROR = 'error';
  svs.components.Alert.Type.DEFAULT = svs.components.Alert.Type.INFO;
})(svs);


 })(window);