(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/alert/assets/javascripts/create-wrapper.js') >= 0) return;  svs.modules.push('/components/components/alert/assets/javascripts/create-wrapper.js');
(function() {
  function createWrapper() {
    var wrapper = $('.alert-wrapper');
    var body = $('body');

    if (!wrapper.length) {
      wrapper = $('<div class="alert-wrapper"></div>').appendTo(body);

      if (!svs.core.detect.formfactor.mobile()) {
        wrapper.css('top', $(window).innerHeight() / 2 - 100);
      }
    }

    return wrapper.get(0);
  }

  svs = svs || {};
  svs.components = svs.components || {};
  svs.components.alert = svs.components.alert || {};
  svs.components.alert.createWrapper = createWrapper;
})();


 })(window);